import React from "react";
import './ourshop.css'

// import Carousel from "../../components/carousel/index";
import Carousel from '../../components/carouselv2/index';


const OurShop =()=>{
    return(
      <div className="mainInfoOS">
           <div className=" shopHeader">
                Our Shop
            </div>
            <hr id="OShr"></hr>
   
<div className="shopInfo">
            <strong>MasterTov Motors Ltd </strong> is a well-known and reputable auto repair shop located in 
            <strong> Toronto (North York) area, Ontario.</strong> It provides Maintenance, 
            Service and Parts for ALL of your Domestic and Import Made Vehicles.
             It has been opened and operated by <strong>Eliya Gehtman</strong>, 
             a certified specialist with more than 30 years experience in autorepairs. 
             Take advantage of our QUALITY Service and Diagnostic Capabilities. 
             Today's cars demand advanced computerized equipment for proper diagnosis 
             by Trained Technicians.
            
            </div>

           <Carousel/>


        </div>
       
    )
}

export default OurShop