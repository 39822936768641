import React from 'react';
import GoogleMaps from "../../components/googlemaps";
import MyForm from '../../components/formspree/index';
import "./contact.css"



const Contact =()=>{
    return (
        <div className="mainInfo">
             <div className="row shopHeader">
                Contact Us 
            </div>
            <hr id="OShr"></hr>
            <div className="form">
            <MyForm></MyForm>
            </div>
            <div className="infoBar">
                <p id="redTitle">
                    Telephone
                </p>
                <p id="white">416-661-1549</p>
                <p id="redTitle">
                Email
                </p>
                <p id="white">
                    4mastertov@gmail.com
                </p>
                <p id="redTitle">
                    Opening Hours
                </p>
                <p id="white">Mon - Fri: 9am - 6pm</p>
                <p id="white"> Saturday: 9am - 1pm</p>
                
            </div>
            <div className="infoBarTele">
                <p id="redTitle">
                    Telephone 
                </p> 
                <p id="white">416-661-1549</p>
                </div>
                <div className="infoBarEmail">
                <p id="redTitle">
                Email
                </p>
                <p id="white">
                    4mastertov@gmail.com
                </p>
                </div>
                <div className="infoBarHours">
                <p id="redTitle">
                    Opening Hours
                </p>
                
                <p id="white">Mon - Fri: 9am - 6pm
                <br></br> Saturday: 9am - 1pm</p>
                
                </div>
           
            
            <div className="address">
                <p id="statments">Located At: </p>
                <p id="addInfo">73 Alness Street, Unit 4,
                North York, ON, Canada</p>
                <GoogleMaps/>
            
            </div>

            
         

            
            
            
        </div>
    )
}
export default Contact;
