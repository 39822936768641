import React from "react";
import logo from "../../assets/Mastertovlogo2.jpg";
import { Link } from 'react-router-dom';
import './navbar.css'


const Navbar =()=>{
        return (
            <nav>
                <Link to="/"><img src={logo} className="logo"></img></Link>
                <ul className="links">
                    <li id="navli"><Link to="/" style={{ textDecoration: 'none' }}><i class="fas fa-home"></i> Home</Link></li>
                    <li id="navli"><Link to="/services" style={{ textDecoration: 'none' }}><i class="fas fa-tools"></i> Services</Link></li>
                    <li id="navli"><Link to="/shop" style={{ textDecoration: 'none' }}><i class="fas fa-warehouse"></i> Our Shop</Link></li>
                    <li id="navli"><Link to="/contactus" style={{ textDecoration: 'none' }}><i class="fas fa-phone"></i> Contact Us</Link></li>
                </ul>
            </nav>


        )
    }

export default Navbar