import React from "react";
import "./home.css";
import bgpic from'../../assets/homebackground.jpg';
import Social from "../../components/social";

const Home =()=>{
    return(
        <div className="mainSection">
          
            
                <div className="bio">
                  AUTOMOTIVE REPAIR SHOP
                </div>

                <div className="genServ">
                   Specializing in all Makes and Models, we pride our selves in providing great service for the best price ! 
                </div>
                

                
        
            </div>



       
    )
}

export default Home