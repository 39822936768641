import React, { Component } from 'react';
import './social.css'
class Social extends Component {
render() {
return (
<div class="social">
<a href="https://www.facebook.com/Mastertov-Motors-163878393678470" target="_blank"><i class="fab fa-facebook"></i></a>
<a href="https://www.instagram.com/mastertov/" target="_blank"><i class="fab fa-instagram-square"></i></a>
</div>
)
}
}

export default Social