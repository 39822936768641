import React from 'react';
import {  BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar/index"
import Home from "./content/home/index";
import Services from "./content/Services";
import OurShop from "./content/OurShop";
import Contact from "./content/Contact ";
import Footer from './components/Footer';
import './App.css';




function App() {
  return (
      <Router>
      <div className="App">
      <Navbar/>
      <Switch>
      <Route exact path='/'>
        <Home/>
      </Route>
      <Route path='/services'>
        <Services/>
      </Route>
      <Route path='/shop'>
        <OurShop/>
      </Route>
      <Route path='/contactus'>
        <Contact/>
      </Route>
      </Switch>
      
     <Footer/>
      </div>
      </Router>
  );
}

export default App;
