import React from "react";
import './footer.css'
import Social from '../social/index'
import { Link } from 'react-router-dom';

const ContactUs =()=>{
    return(
        <footer>
            <div className="description row">
                <div className="phone col-4 ">
                    <h4 class="footh4"><i class="fas fa-phone"></i> Call Us</h4>
                    <a href="tel:+1-416-661-1549" class="footp" id="numb">416-661-1549</a> 
                </div>
                <div className ="email col-4 border-left border-right">
                    <Link to='/contactus'style={{ textDecoration: 'none' }}><h4 class="footh4"><i class="fas fa-envelope-open-text"></i> Email Us</h4></Link>
                    <Social/>
                </div>
                <div className="hours col-4">
                    <h4 class="footh4"><i class="far fa-clock"></i> Hours Of Operation</h4>
                    <div className="info">
                    <p class="footp">Mon-Fri : 9am-6pm</p>
                    <p class="footp">Sat : 9am-1pm</p>
                    </div>
                </div>
            </div>
            </footer>



    )
}

export default ContactUs