import React from "react";
import './services.css'
import Table from 'react-bootstrap/Table'
import mechImage from '../../assets/mechImage.jpg';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import oil from '../../assets/oil.png'
import tire from '../../assets/tire.png'
import motor from '../../assets/motor.png'
import belts from '../../assets/belt.png'
import battery from "../../assets/battery.png"
import windshield from '../../assets/windshield.png'
import airCondition from '../../assets/cooler.png'
import sprayBottle from '../../assets/spray.png'
import coolant from '../../assets/coolant.png'
import task from '../../assets/task.png'
import engine from'../../assets/motor.png'
import brake from '../../assets/brake.png'
import suspension from '../../assets/suspension.png'
import ignition from '../../assets/ignition.png'
import muffler from '../../assets/muffler.png'
import carlight from '../../assets/car-light.png'
import wheel from '../../assets/car.png'
import door from '../../assets/door.png'
import diagnostic from'../../assets/diagnostic.png'
import car from '../../assets/car.png'
import paint from '../../assets/paint.png'
import crash from '../../assets/crash.png'
const Services =()=>{
    return(
        <div className="servMain">
            <div className="row servHeader">
                Shop Services
            </div>
            <hr></hr>
            <img  className="mechImage" src={mechImage}/>
            <h3 id="genState"> SPECIALIZING IN ALL AUTOMOTIVE ELECTRICAL & MECHANICAL REPAIR </h3>

            <Accordion style={{position:"relative"}} defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} style={{ backgroundColor: 'rgb(164, 10, 10)', color:"white" }} eventKey="0">
                    <h4 id="collapseHeaders">Maintenance Services <i class="fas fa-chevron-down"></i></h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{backgroundColor:"rgb(255, 255, 255)"}}>
                            <ul className="mainList">
                                <li id="mTitle">Scheduled Services</li>
                                    <div id="serTitle"> <img id="servicon" src={oil}></img>Oil Change & Filter Replacement</div>
                                        <p id="servDesc">We use recommended oil and quality filters for all makes and models, whether its synthetic or regular we got you covered.   </p>
                                    <div id="serTitle"> <img id="servicon" src={tire}></img>Tire Rotation & Wheel Balancing</div>
                                        <p id="servDesc">Prolong the life of your tires by rotating and balancing, its an efficient and easy method to maintain tire thread.</p>
                                    <div id="serTitle"> <img id="servicon" src={motor}></img>Engine Filters Replacement</div>
                                        <p id="servDesc">Replacing your Air filter, Fuel filter and Cabin filter is an important step for proper maintenance of your vehicle.  </p>
                                    <div id="serTitle"> <img id="servicon" src={belts}></img>Engine Belts & Hose Replacements</div>
                                        <p id="servDesc">Preemptively changing your serpentine belts and engine hoses can prevent a lot of damage in the future, our trained professinals will check and inform you when its time for replacement.</p>
                                <li id="mTitle">Seasonal Services</li>
                                    <div id="serTitle"> <img id="servicon" src={battery}></img>Battery Performance Check & Maintenance</div>
                                        <p id="servDesc">Always be winter ready, get a battery performance check to make sure you won't have any issues when the cold weather hits. Our technicians will provide Terminal Cleaning, Cable Replacement, and if needed Battery Replacement as well.   </p>
                                    <div id="serTitle"> <img id="servicon" src={windshield}></img>Windsheild Wiper Replacement</div>
                                        <p id="servDesc">Streaking wiper blades are a common sign of disintegration, when the extreme weather hits you don't want to be stuck with fualty wiper blades.</p>
                                    <div id="serTitle"> <img id="servicon" src={tire}></img>Tire Purchase & Installation</div>
                                        <p id="servDesc">Whether you're looking to install you're winter or all season tires or purchase new ones we can provide you with competative prices and quality service.    </p>
                                    <div id="serTitle"> <img id="servicon" src={airCondition}></img>A/C & Heating Service</div>
                                        <p id="servDesc">When the seasons change don't get stuck in the winter without heat or in the summer without A/C, get your climate control serviced. </p>
                                <li id="mTitle">Preventative Services</li>
                                    <div id="serTitle"> <img id="servicon" src={coolant}></img>Fluid Flush & Replacement</div>
                                        <p id="servDesc"><ul>
                                            <li>Engine Coolant Flush</li>
                                            <li>Motor Flush</li>
                                            <li>Power Steering Flush</li>
                                            <li>Brake fluid Flush</li>
                                            <li>Transmission Flush</li>

                                            </ul>All of these methods are important to remove sludge and dirt and maintaing the performance of your vehicle. </p>
                                    <div id="serTitle"> <img id="servicon" src={sprayBottle}></img>Rust Proofing & Undercoating</div>
                                        <p id="servDesc">Whether you just got a new car or want to prolong your used car, Rust Proofing and Undercoating are essential steps to fight off the wet canadian weather. Keeping the undercarriage of your vehicle rust free will help prolong its appearance and performance for years to come.</p>
                                    <div id="serTitle"> <img id="servicon" src={task}></img>Service Check</div>
                                        <p id="servDesc">Its always important to get your car service checked by a professional technician, it will help avoid future mulfunctions and problems. Our mechanics will perform a full automotive assessment and provide feedback about when its time to service your vehicle.</p>
                                   

                                        
                          
                            </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>


                <Card>
                    <Accordion.Toggle as={Card.Header}style={{ backgroundColor: 'rgb(164, 10, 10)', color:"white" }} eventKey="1">
                        <h4 id="collapseHeaders">Automotive Repair Services <i class="fas fa-chevron-down"></i></h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body style={{backgroundColor:"rgb(255, 255, 255)"}}>
                        <div id="serTitle"> <img id="servicon" src={engine}></img>Engine Service</div>
                        <p id="servDesc">Whether you need a tune-up or more extensive work like an engine rebuild, our technicians are trained and experienced in all levels of service.  </p>
                        <div id="serTitle"> <img id="servicon" src={brake}></img>Brake Service</div>
                        <p id="servDesc"><ul>
                                            <li>Brake Pad or Shoe Replacement</li>
                                            <li>Brake Rotor Resurfacing / Brake Drum Turning</li>
                                            <li>Brake Rotor or Drum Replacment</li>
                                            <li>Caliper Replacement</li>
                                            <li>Brake Line Service</li>
                                            <li>Brake Fluid Service</li>
                                            <li>Anti-Lock Brake System Service</li>
                                         </ul>  </p>
                        <div id="serTitle"> <img id="servicon" src={suspension}></img>Steering & Suspension Service</div>
                        <p id="servDesc"><ul>
                                <li>Shocks & Struts Replacement</li>
                                <li>Ball Joint Replacement</li>
                                <li>Tie Rod Replacement</li>
                                <li>Sway Bar Replacement</li>
                                <li>Steering System Repair</li>
                            </ul></p>
                        <div id="serTitle"> <img id="servicon" src={wheel}></img>Power Steering Service</div>
                        <p id="servDesc"><ul>
                            <li>Power Steering Leak Detection</li>
                            <li>Power Steering Hose & Fluid Replacement</li>
                            <li>Power Steering Pump Replacement</li>
                            </ul></p>
                        <div id="serTitle"> <img id="servicon" src={ignition}></img>Fuel System Service</div>
                        <p id="servDesc"><ul>
                            <li>Fuel System Flush</li>
                            <li>Fuel Line Repair</li>
                            <li>Fuel Pump Replacement</li>
                            <li>Fuel Injector Replacement </li>
                        </ul>
                        </p>
                        <div id="serTitle"> <img id="servicon" src={muffler}></img>Muffler & Exhuast Service</div>
                        <p id="servDesc"><ul>
                                <li>Muffler Replacment & Repair</li>
                                <li>Oxygen Sensor Replacement</li>
                                <li>Catalytic Converter Replacment</li>
                            </ul></p>
                        <div id="serTitle"> <img id="servicon" src={carlight}></img>Electrical System Service</div>
                        <p id="servDesc"><ul>
                            <li>Battery Replacement or Charge Up</li>
                            <li>Starter & Alternator Replacement</li>
                            <li>Modular Components Replacement</li>
                            <li>Custom Headlight Installation</li>
                            <li>Bulb Replacement</li>
                        </ul>
                        </p>
                        <div id="serTitle"> <img id="servicon" src={task}></img> Transmission Service </div>
                             <p id="servDesc"><ul>
                                    <li>Transmission Maintence: Flushing and replacement of transmission fluid.</li>
                                    <li>Transmission Replacement</li>
                            </ul> </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            
                <Card>
                    <Accordion.Toggle style={{ backgroundColor: 'rgb(164, 10, 10)', color:"white" }} as={Card.Header} eventKey="2">
                    <h4 id="collapseHeaders">Body Repair Services <i class="fas fa-chevron-down"></i></h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                    <Card.Body style={{backgroundColor:"rgb(255, 255, 255)"}}>
                    <div id="serTitle"> <img id="servicon" src={paint}></img> Dent & Scratch Repair </div>
                        <p id="servDesc"> Our technicians are trained to repair any Dent or Scratch, we use the best products and techniques to get your car back to its original form.  </p>
                    <div id="serTitle"> <img id="servicon" src={door}></img>Collision Repair </div>
                        <p id="servDesc">Accidents happen, our team provides high quailty labour with OEM standard parts to get your vehicle back on the road good as new. </p>
                    <div id="serTitle"> <img id="servicon" src={windshield}></img> Windshield Service </div>
                        <p id="servDesc"> Don't wait for that chip in your windshield to turn into a crack, we repair all chips, and offer windshield replacements as well.  </p>
                        <div id="serTitle"> <img id="servicon" src={crash}></img> Post Collision Insurance Service </div>
                        <p id="servDesc">Accidents are always a hassle, let use deal with your insurance and make sure your claim goes through. </p>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle style={{ backgroundColor: 'rgb(164, 10, 10)', color:"white" }} as={Card.Header} eventKey="3">
                    <h4 id="collapseHeaders">Assessments & Diagnostics <i class="fas fa-chevron-down"></i></h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                    <Card.Body style={{backgroundColor:"rgb(255, 255, 255)"}}>
                    <div id="serTitle"> <img id="servicon" src={diagnostic}></img> Computer Diagnostics </div>
                            <p id="servDesc">Don't let that flashing light on your console linger for to long, we have advanced computerized diagnostic equipment thats compatible with all makes and models, our licensed techinicans will thourghly inpsect your vehicle and figure out the problem. </p>
                        <div id="serTitle"> <img id="servicon" src={task}></img>MTO Safety Inspections </div>
                            <p id="servDesc"> Our shop is a MTO licensed vehicle inspection station.  </p>
                        <div id="serTitle"> <img id="servicon" src={task}></img> Pre-Purchase Inspection </div>
                             <p id="servDesc"> Looking to buy a used car ? Our mechanics will provide a full vehicle inspection and help you determine what kind of shape the vehicle is in and if it's worth that price.  </p>
                        <div id="serTitle"> <img id="servicon" src={car}></img>Alignment and Steering  </div>
                             <p id="servDesc"> Our shop has advanced Alignment equipment to get you driving straight again. </p>
                        </Card.Body> 
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <div className="recArea">
            <div className="row servHeader">
                Service Recommendations
            </div>
            <hr></hr>
            <Table responsive striped bordered hover variant="dark">
  <thead>
    <tr>
      <th>Service</th>
      <th>Recommended Milage</th>
      <th>Recommended Time Period</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Oil Change</td>
      <td>Synthetic: 10,000 km
          <br></br>
          Convential: 5,000 km
      </td>
      <td>Synthetic: 6 Months
          <br></br>
          Convential: 3 Months
      </td>
    </tr>
    <tr>
        <td>Air Filter</td>
        <td>20,000 km</td>
        <td>12 Months</td>
    </tr>
    <tr>
        <td>Cabin Air Filter</td>
        <td>20,000 km</td>
        <td>12 Months</td>
    </tr>
    <tr>
      <td>Tire Rotation</td>
      <td>7,000 km</td>
      <td>6 Months</td>
    </tr>
    <tr>
        <td>Engine Belts</td>
        <td>30,000 -
            <br></br>
            50,000 km
        </td>
        <td>2-3 Years</td>
    </tr>
    <tr>
        <td>Engine Hoses</td>
        <td>60,000 -
            <br></br>
            80,000 km
        </td>
        <td>4 Years</td>
    </tr>
  </tbody>
</Table>

            </div>

            
        </div>
        

    
        
    )
}

export default Services