import React, { useState } from 'react';
import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css'
import photo from '../../assets/IMG_7263_DxO.JPG'
import photo1 from '../../assets/IMG_7264_DxO.jpg'
import photo2 from '../../assets/DJI_0596.jpg'
import photo3 from '../../assets/IMG_7268_DxO.jpg'
import photo4 from '../../assets/IMG_7269_DxO.jpg'
import photo5 from '../../assets/IMG_7270_DxO.jpg'
import photo6 from '../../assets/IMG_7272_DxO.jpg'
import photo7 from '../../assets/IMG_7274_DxO.jpg'
import photo8 from '../../assets/IMG_7275_DxO.jpg'
import photo22 from '../../assets/IMG_7277_DxO.jpg'
import photo9 from '../../assets/IMG_7278_DxO.jpg'
import photo10 from '../../assets/IMG_7279_DxO.jpg'
import photo11 from '../../assets/IMG_7280_DxO.jpg'
import photo12 from '../../assets/IMG_7282_DxO.jpg'
import photo13 from '../../assets/IMG_7283_DxO.jpg'
import photo14 from '../../assets/IMG_7285_DxO.jpg'
import photo15 from '../../assets/IMG_7286_DxO.jpg'
import photo16 from '../../assets/IMG_7287_DxO.jpg'
import photo17 from '../../assets/IMG_7289_DxO.jpg'
import photo18 from '../../assets/IMG_7293_DxO.jpg'
import photo19 from '../../assets/IMG_7298_DxO.jpg'
import photo20 from '../../assets/IMG_7304_DxO.jpg'
import photo21 from '../../assets/IMG_7305_DxO.jpg'
import shopfront from '../../assets/DJI_0585.JPG'
import shopBack from '../../assets/DJI_0596.jpg'
import alignment from'../../assets/DJI_0614.JPG';
import './style.css';


const PHOTOS = [photo,photo1,photo2,photo3,photo4,photo5,photo6,photo7,photo8,photo9,photo10,photo11,photo12,photo13,photo14,photo15,photo16,photo17,photo18,photo19,photo20,photo21,photo22];

 const  GalleryExample = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        <button className="button1" onClick={() => setIsOpen(true)}>
          <img className="photo3" src={shopfront}></img>
         
        </button>
        <button className="button3" onClick={() => setIsOpen(true)}>
          <img className="photo1" src={photo12}></img>
         
        </button>
        <button className="button2" onClick={() => setIsOpen(true)}>
          <img className="photo2" src={photo1}></img>
         
         </button>
       
        <button className="button4" onClick={() => setIsOpen(true)}>
          <img className="photo4" src={shopBack}></img>
         
        </button>
        <ReactBnbGallery
          show={isOpen}
          photos={PHOTOS}
          showThumbnails={true}
          onClose={() => setIsOpen(false)}
        />
      </>
    );
  };

export default GalleryExample;