import React from "react";
import "./form.css";
export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/xgelvyod"
        method="POST"
        
      >
        <div className="from-group">
        <label> Enter Your Name:</label>
        <br></br>
        <input type="text" name="name"  class="form-control " placeholder="Name" />
        </div>
        <div className="from-group">
        <label>Enter Your Number:</label>
        <br></br>
        <input type="integer" name="Number"  class="form-control " placeholder="Number" />
        </div>
        <div className="form-group">
        <label> Enter Your Email:</label>
        <br></br>
        <input type="email" name="email" class="form-control " placeholder="Email Address"/>
        </div>
        <div className ="from-group">
        <label> Enter Your Message:</label>
        <br></br>
        <textarea class="form-control form-control-lg" type="text" name="message" placeholder="Message" row="4" />
        </div>
        {status === "SUCCESS" ? <p>Thanks!</p> : <button  style ={{ backgroundColor:"rgb(164, 10, 10)", color:"white"}} type="button" class=" btn btn-lg ">Submit</button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}